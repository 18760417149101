"use client";

import { useEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { BasicUserForm } from "@/components/BasicUserForm/BasicUserForm";
import { TimezoneSelectorForm } from "@/components/TimezoneSelectorForm/TimezoneSelectorForm";
import { Button } from "@/components/ui/Button";
import { SimpleDialog } from "@/components/ui/dialog";
import { Divider } from "@/components/ui/Divider";
import { SimpleSheet } from "@/components/ui/sheet";
import { Text } from "@/components/ui/Text";
import { useToggleIntercom } from "@/hooks/useHideIntercom";
import { type SupportedLanguage } from "@/utils/i18n";
import { trpc } from "@/utils/TrpcProvider";
import { cn } from "@/utils/tw";
import { motion } from "framer-motion";
import { AllListingsButton } from "./(marketing)/common/AllListingsButton";
import { ContextButton } from "./(marketing)/common/ContextButton";
import { MobileHeaderSlider } from "./(marketing)/common/MobileHeaderSlider";
import { UserMenu } from "./UserMenu";
export const heightTopbar = 90; // if we are sliding out a nav bar at the top of the screen, this will be it's height

export const Nav = ({
  onChangeEnded,
  override
}: {
  lang: SupportedLanguage;
  onChangeEnded?: (key: string) => void;
  override?: string | null;
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const router = useRouter();
  useEffect(() => {
    let lastScroll = 0;
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      setIsVisible(currentScroll < lastScroll || currentScroll <= heightTopbar);
      lastScroll = currentScroll;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const pathName = usePathname();
  const current = override || pathName;
  const currentPage = useMemo(() => {
    if (current === "/") return "Vouchers";
    if (current === "/companies") return "Companies";
    if (current === "/candidates") return "Candidates";
  }, [current]);
  const {
    showIntercom,
    hideIntercom,
    isIntercomHidden
  } = useToggleIntercom();
  const isIntercomAlreadyHidden = useRef(false);
  useEffect(() => {
    if (isSheetOpen) {
      isIntercomAlreadyHidden.current = isIntercomHidden.current;
      hideIntercom();
    } else {
      if (!isIntercomAlreadyHidden.current) {
        showIntercom();
      }
    }
  }, [isSheetOpen, showIntercom, hideIntercom, isIntercomHidden]);
  const {
    data: user
  } = trpc.user.getUser.useQuery();
  const [hasSaved, setHasSaved] = useState(false);
  return <>
      {user && (user.firstName.trim() === "" || user.lastName.trim() === "" ? <SimpleDialog open={!hasSaved}>
            <BasicUserForm onSave={() => {
        setHasSaved(true);
      }} />
          </SimpleDialog> : <TimezoneSelectorForm />)}
      <motion.header className={cn("top-0 z-20 w-dvw bg-beige-3 transition-all", {
      fixed: true
    })} animate={{
      y: isVisible ? 0 : -heightTopbar
    }} transition={{
      duration: 0.2,
      delay: 0,
      ease: "easeInOut"
    }} data-sentry-element="unknown" data-sentry-source-file="Nav.tsx">
        <div className="container flex items-center justify-between gap-8 py-6">
          <div className="flex items-center gap-4">
            <Link href="/" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
              <Image src="/logo.svg" alt="Vouch" width={108} height={24} data-sentry-element="Image" data-sentry-source-file="Nav.tsx" />
            </Link>
            <ContextButton className="max-lg:hidden" data-sentry-element="ContextButton" data-sentry-source-file="Nav.tsx" />
          </div>
          <div className="flex items-center justify-center gap-4 rounded-full max-md:hidden">
            <Link href="/" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
              <Button variant={current === "/" ? "purple" : "ghost"} className="rounded-2xl transition-all" data-sentry-element="Button" data-sentry-source-file="Nav.tsx">
                Vouchers
              </Button>
            </Link>
            <Link href="/companies" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
              <Button variant={current === "/companies" ? "purple" : "ghost"} className="rounded-2xl transition-all" data-sentry-element="Button" data-sentry-source-file="Nav.tsx">
                Companies
              </Button>
            </Link>
            <Link href="/candidates" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
              <Button variant={current === "/candidates" ? "purple" : "ghost"} className="rounded-2xl transition-all" data-sentry-element="Button" data-sentry-source-file="Nav.tsx">
                Candidates
              </Button>
            </Link>
          </div>
          <div className="md:hidden">
            <SimpleSheet open={isSheetOpen} className="h-dvh w-dvw bg-beige-3 px-0 py-0" hideClose setOpen={setSheetOpen} position="top" content={<div className="flex h-full flex-col">
                  <div className="flex flex-1 flex-col gap-4">
                    <div className="container flex items-center justify-between gap-8 pt-8">
                      <Image src="/logo.svg" alt="Vouch" width={108} height={24} />
                      <Button variant="beige" icon="upChevron" onClick={() => setSheetOpen(false)} rounded size="sm" />
                    </div>
                    <MobileHeaderSlider initialActiveKey={current || "/"} onChange={key => {
                if (key !== current) {
                  router.push(key);
                }
                onChangeEnded?.(key);
                setSheetOpen(false);
              }} />
                    <div className="container flex-1 space-y-3 overflow-auto">
                      <Text.P small muted>
                        Company
                      </Text.P>
                      <Link href="/about" className="block" onClick={() => {
                  setSheetOpen(false);
                }}>
                        About
                      </Link>
                      <Link href="/blog" className="block" onClick={() => {
                  setSheetOpen(false);
                }}>
                        Blog
                      </Link>
                      <a id="open-intercom" className="block" onClick={() => {
                  setSheetOpen(false);
                }}>
                        Contact
                      </a>
                      <Divider />
                      <Text.P small muted>
                        Product
                      </Text.P>
                      <Link href="https://chat.buility.com?cid=661925ba2db8330010127953" target="_blank" className="block" onClick={() => {
                  setSheetOpen(false);
                }}>
                        Feedback
                      </Link>
                      <Link href="https://playbook.vouch.careers" target="_blank" className="block" onClick={() => {
                  setSheetOpen(false);
                }}>
                        Playbooks
                      </Link>
                    </div>
                  </div>
                  <div className="container w-full py-4">
                    <StickyFooterContent onClose={() => setSheetOpen(false)} />
                  </div>
                </div>} data-sentry-element="SimpleSheet" data-sentry-source-file="Nav.tsx">
              <Button variant="beige" className="gap-0 rounded-2xl md:hidden" icon="downChevron" data-sentry-element="Button" data-sentry-source-file="Nav.tsx">
                {currentPage}
              </Button>
            </SimpleSheet>
          </div>
          <div className="relative flex items-center gap-4 max-lg:hidden">
            <AllListingsButton data-sentry-element="AllListingsButton" data-sentry-source-file="Nav.tsx" />
            <UserMenu variant="light" data-sentry-element="UserMenu" data-sentry-source-file="Nav.tsx" />
          </div>
        </div>
      </motion.header>
    </>;
};
export const StickyFooterContent = ({
  onClose
}: {
  onClose?: () => void;
}) => {
  return <div className="flex items-center justify-between gap-2" data-sentry-component="StickyFooterContent" data-sentry-source-file="Nav.tsx">
      <div className="flex gap-1">
        <ContextButton data-sentry-element="ContextButton" data-sentry-source-file="Nav.tsx" />
        <AllListingsButton data-sentry-element="AllListingsButton" data-sentry-source-file="Nav.tsx" />
      </div>
      <div className="relative flex items-center gap-4 self-end">
        <UserMenu variant="light" onButtonClick={onClose} data-sentry-element="UserMenu" data-sentry-source-file="Nav.tsx" />
      </div>
    </div>;
};