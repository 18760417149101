"use client";

import type { ButtonProps } from "@/components/ui/Button";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Button } from "@/components/ui/Button";
import { useIsClient } from "@/hooks/useIsClient";
import { cn } from "@/utils/tw";
import { useLocaleProvider } from "../../LocaleProvider";
export const ContextButton = (props: ButtonProps) => {
  const {
    sourceContext,
    country,
    setSourceContext
  } = useLocaleProvider();
  const isClient = useIsClient();
  const pathName = usePathname();
  if (!isClient) {
    return null;
  }
  if (sourceContext) {
    let text = "";
    switch (sourceContext.type) {
      case "company":
        text = "Back to company";
        break;
      case "listing":
        text = "Back to listing";
        break;
      case "vouch":
        text = "Your vouch";
        break;
    }
    const path = sourceContext.url.substring(1).split("/")?.[0];
    if (path && !pathName?.includes(`/${path}`)) {
      return <div className="relative">
          <Link href={sourceContext.url}>
            <Button variant="beige" icon="leftArrow" iconPlacement="left" rounded {...props}>
              {text}{" "}
            </Button>
          </Link>
          <Button size="xs" icon="close" variant="white" className={cn("absolute right-0 top-0 -translate-y-1/2 translate-x-1/2", props.className)} onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setSourceContext(null);
        }} />
        </div>;
    }
  }
  if (pathName?.includes("/listings/")) {
    return <Link href="/discovery" className={cn({
      hidden: country !== "NO"
    })}>
        <Button variant="beige" rounded icon="leftArrow" iconPlacement="left" {...props}>
          All Listings
        </Button>
      </Link>;
  }
  return null;
};