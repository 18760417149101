import { env } from "@/env.mjs";
import posthog from "posthog-js";

import * as fbq from "./fpixel";

type Metrics =
  | "Discovery"
  | "Discovery.Company"
  | "Listing.Vouch"
  | "Marketing.Listing.Generate"
  | "Vouch.Login"
  | "Vouch.Created"
  | "Vouch.StartedEditing"
  | "Vouch.Share"
  | "Vouch.Error"
  | "Vouch.Reject"
  | "Vouch.Reject.AddReason"
  | "Vouch.Accept"
  | "Vouch.Accept.FormEdited"
  | "Vouch.Accept.Submit"
  | "Vouch.Edit.FormEdited"
  | "Vouch.Edit.Submit"
  | "Vouch.Motivation.FormEdited"
  | "Vouch.Motivation.Submit"
  | "Business.Create"
  | "Business.Create.FormEdited"
  | "Business.Create.Submit"
  | "Business.Create.Error"
  | "Business.Edit"
  | "Business.Edit.FormEdited"
  | "Business.Edit.Submit"
  | "Business.Edit.Error"
  | "Listing.Create"
  | "Listing.Create.FormEdited"
  | "Listing.Create.Save"
  | "Listing.Create.Save.AI"
  | "Listing.Create.Preview"
  | "Listing.Create.Submit"
  | "Listing.Create.Error"
  | "Listing.Edit"
  | "Listing.Edit.FormEdited"
  | "Listing.Edit.Preview"
  | "Listing.Edit.Submit"
  | "Listing.Edit.Error"
  | "Candidate.ChangeStage"
  | "Candidate.ChangeStage.Error"
  | "Candidate.ViewProfile"
  | "Candidate.Reject"
  | "Candidate.Reject.FormEdited"
  | "Candidate.Reject.Send"
  | "Candidate.Connect"
  | "Candidate.Connect.FormEdited"
  | "Candidate.Connect.Send"
  | "Candidate.RejectInProcess"
  | "Candidate.RejectInProcess.FormEdited"
  | "Candidate.RejectInProcess.Send"
  | "Candidate.StartDate"
  | "Candidate.StartDate.Send"
  | "Business.Settings.GlobalAccess.Error"
  | "Business.Settings.ListingAccess.Error"
  // generic
  | "Form.Error"
  | "Auth.Error";

export const trackUsage = (metrics: Metrics, body?: Record<string, unknown>) => {
  if (env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
    fbq.event(`UI-${metrics}`, body);
  }
  if (!env.NEXT_PUBLIC_POSTHOG_KEY || !env.NEXT_PUBLIC_POSTHOG_HOST) return;
  if (!posthog.__loaded) return;
  posthog.capture(`UI-${metrics}`, body);
};
