"use client";

import type { CountryCode } from "libphonenumber-js";
import type { Country, Value as PhoneInputType } from "react-phone-number-input";
import React, { useMemo, useState } from "react";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { getCountryFromTimezone } from "@/utils/getCountryFromTimezone";
import { type PageContent, type SupportedLanguage } from "@/utils/i18n";
import { isSupportedSmsCountry } from "@/utils/smsCountries";
import { cn } from "@/utils/tw";
import { getCountryCallingCode, parse } from "libphonenumber-js";
import { getCountries } from "react-phone-number-input";
import ReactPhoneInput from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import no from "react-phone-number-input/locale/nb.json";
import { ComboBoxDynamic } from "../ui/formInputs/Combobox";
import { Input } from "../ui/Input";
const t = {
  placeholderCountry: {
    en: "Country code",
    no: "Landkode",
    dk: "Landekode"
  },
  placeholder: {
    no: "00 00 00 00",
    en: "000 000 0000",
    dk: "00 00 00 00"
  },
  disabled: {
    en: "Country is not supported yet",
    no: "Landet er ikke støttet ennå",
    dk: "Landet er endnu ikke understøttet"
  }
} satisfies PageContent;
const labels = {
  no,
  en,
  dk: en
} satisfies Record<SupportedLanguage, Record<string, string>>;
export type PhoneInputValue = PhoneInputType;
export const PhoneInput = ({
  setValue,
  value,
  disabled,
  placeholder,
  onBlur,
  countySelectorClassName,
  inputClassName
}: {
  value?: PhoneInputValue | string;
  setValue: (val: PhoneInputValue) => void;
  defaultCountry?: Country;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: () => void;
  countySelectorClassName?: string;
  inputClassName?: string;
}) => {
  const {
    lang
  } = useLocaleProvider();
  const [country, setCountry] = useState(() => {
    const country = value ? parse(value).country : getCountryFromTimezone();
    return country as CountryCode | undefined;
  });
  const phoneCountryOptions = useMemo(() => {
    return getCountries().filter(c => isSupportedSmsCountry(c)).map(country => ({
      label: `${labels[lang][country]} (+${getCountryCallingCode(country)})`,
      value: country,
      disabledTooltip: t.disabled[lang]
    })).sort((a, b) => a.label.localeCompare(b.label, lang));
  }, [lang]);
  const defaultCountryCode = phoneCountryOptions.find(o => o.value === country?.toUpperCase()) ?? {
    label: "Norway (+47)",
    value: "NO"
  };
  return <div className="flex w-full flex-wrap items-stretch gap-2" data-sentry-component="PhoneInput" data-sentry-source-file="PhoneInput.tsx">
      <ComboBoxDynamic triggerClassName={cn("h-auto w-40 md:h-full", countySelectorClassName)} wrapperClassName="h-auto" options={phoneCountryOptions} placeholder={t.placeholderCountry[lang]} searchPlaceholder={t.placeholderCountry[lang]} defaultValue={defaultCountryCode} onSelectCallback={option => {
      if (option) {
        setCountry(option.value);
      }
    }} data-sentry-element="ComboBoxDynamic" data-sentry-source-file="PhoneInput.tsx" />
      <ReactPhoneInput placeholder={placeholder || t.placeholder[lang]} country={country} value={value} onChange={val => {
      setValue(val || "" as PhoneInputValue);
    }} inputComponent={Input} className={cn("w-0 min-w-24 flex-1", inputClassName)} disabled={disabled} onBlur={onBlur} data-sentry-element="ReactPhoneInput" data-sentry-source-file="PhoneInput.tsx" />
    </div>;
};