import type { Prisma } from "@prisma/client";
import type { TCountryCode } from "countries-list";
import { zCompanySectionContent, zCompanySectionContentInput } from "@/types/CompanySectionContent";
import { zSubscriptionCurrency, zSubscriptionPeriod } from "@/utils/stripe.utils";
import {
  CoreSkills,
  Currency,
  Experience,
  Industry,
  Language,
  SubscriptionPlan,
} from "@prisma/client";
import { countries } from "countries-list";
import { isValidPhoneNumber } from "libphonenumber-js";
import { capitalize } from "radash";
import { z } from "zod";

const URLRegex = new RegExp(
  "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
);
const UrlRegex2 = new RegExp("^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

const countryNames = Object.keys(countries) as [TCountryCode, ...TCountryCode[]];
export const CountrySchema = z.enum(countryNames);
const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema.nullable()), z.record(jsonSchema.nullable())]),
);

export const CurrencySchema = z.nativeEnum(Currency);

export const IndustrySchema = z.nativeEnum(Industry);
export const ExperienceSchema = z.nativeEnum(Experience);

export const CoreSkillsSchema = z.nativeEnum(CoreSkills);

const urlField = z.string().refine(
  (s) => {
    return z
      .string()
      .regex(URLRegex, { message: "URL" })
      .or(z.string().regex(UrlRegex2))
      .safeParse(s).success;
  },
  {
    params: {
      i18n: "invalid_url",
    },
  },
);

export const vouchFormSchema = z.object({
  // candidate info
  candidateFirstName: z
    .string()
    .trim()
    .min(1)
    .max(50)
    .transform((x: string) => capitalize(x.trim())),
  candidateLastName: z
    .string()
    .trim()
    .min(1)
    .max(50)
    .transform((x: string) => capitalize(x.trim())),

  // vouch info
  candidateFitDescription: z.string().min(1).max(10000),
  personalMessage: z.string().max(150).optional(),
  candidateEmail: z.string().email().optional(), // make this more elegant?
  candidatePhone: z
    .string()
    .refine((el) => isValidPhoneNumber(el), { params: { i18n: "invalid_number" } })
    .optional(),
  sendViaVouch: z.boolean(),
});

export const voucherUpdateVouchFormSchema = z.object({
  // candidate info
  candidateFirstName: z.string().trim().min(1).max(50).optional(),
  candidateLastName: z.string().trim().min(1).max(50).optional(),
  candidateFitDescription: z.string().min(1).max(10000).optional(),

  // vouch info
  email: z.string().email().optional().nullable(), // make this more elegant?
  phone: z
    .string()
    .optional()
    .nullable()
    .refine((el) => (!el ? true : isValidPhoneNumber(el)), { params: { i18n: "invalid_number" } }),
  id: z.string(),
  sendViaVouch: z.boolean(),
});
export const candidateUpdateVouchFormSchema = z.object({
  candidateCoverLetter: z.string().min(1).max(10000),
});

export const businessFormSchema = z.object({
  // candidate info
  orgNumber: z.string(), // look at syncing this sub schema with the frontend
  name: z.string(),
  website: urlField,
  logoUrl: z.string().url().optional(),
  bannerUrl: z.string().url().optional(),
  contactPersonName: z.string(),
  contactPersonEmail: z.string().email(),
  contactPersonPhone: z
    .string()
    .refine((el) => isValidPhoneNumber(el), { params: { i18n: "invalid_number" } })
    .optional(),
  description: z.string(),
  location: z.string().optional(),
  founded: z.number().int().min(1000).max(2030).optional(),
  employeeCount: z.number().int().optional(),
  industries: z.nativeEnum(Industry).array().max(20),
  country: CountrySchema,
  language: z.nativeEnum(Language).optional(),
  businessModel: z.string().optional(),
  fundingStage: z.string().optional(),
  perksAndBenefits: z // ? what to do
    .object({
      name: z.string(),
      description: z.string().optional(),
    })
    .array()
    .max(20),
  quote: z // ? what to do
    .object({
      name: z.string(),
      position: z.string().optional(),
      quote: z.string(),
    })
    .array()
    .max(20)
    .optional(),
  keyNumbers: z // ? what to do
    .object({
      name: z.string(),
      description: z.string(),
    })
    .array()
    .max(20)
    .optional(),
  leadershipTeam: z // ? what to do
    .object({
      name: z.string(),
      photo: z.string().url().optional(),
      position: z.string(),
      description: z.string().optional(),
    })
    .array()
    .max(20)
    .optional(),
  SDGs: z.string().array().optional(), // ? what is this
});
export const basicCompanyForm = z.object({
  website: urlField,
  country: CountrySchema,
  language: z.nativeEnum(Language),

  orgNumber: z.string().optional(),
  name: z.string().min(1),
  logoUrl: z.string().url().optional(),

  brandColor: z.string().optional(),

  sections: z.array(zCompanySectionContentInput).max(20),

  pendingCompanyId: z.string().nullish(),
  indicatedPlanAtSignUp: z.nativeEnum(SubscriptionPlan).nullish(),
  indicatedCurrencyAtSignUp: zSubscriptionCurrency.nullish(),
  indicatedPeriodAtSignUp: zSubscriptionPeriod.nullish(),

  companyLegalName: z.string().optional(),
});

export const companyFormSchemaV2 = z.object({
  orgNumber: z.string().optional(),
  name: z.string().optional(),
  website: urlField.optional(),
  logoUrl: z.string().url().optional().nullable(),
  contactPersonName: z.string().optional(),
  contactPersonEmail: z.string().email().optional(),
  contactPersonPhone: z
    .string()
    .refine((el) => isValidPhoneNumber(el), { params: { i18n: "invalid_number" } })
    .optional(),
  description: z.string().optional(),
  industries: z.nativeEnum(Industry).array().max(20).optional(),
  country: CountrySchema.optional(),
  language: z.nativeEnum(Language).optional(),
  brandColor: z.string().optional(),
  sections: z.array(zCompanySectionContent).max(20).optional(),
});

export const claimCandidateSchema = z.object({
  linkedInUrl: urlField,
  firstName: z.string().trim().min(1).max(50),
  lastName: z.string().trim().min(1).max(50),
  email: z.string().email(),
  phone: z
    .string()
    .refine((el) => isValidPhoneNumber(el), { params: { i18n: "invalid_number" } })
    .optional(),
});

export const userDetailSchema = z
  .object({
    linkedInUrl: urlField.nullable().optional(),
    firstName: z.string().trim().min(1).max(50),
    lastName: z.string().trim().min(1).max(50),
    email: z.string().trim().email().optional(),
    phone: z
      .string()
      .refine((el) => isValidPhoneNumber(el), { params: { i18n: "invalid_number" } })
      .nullable()
      .optional(),
    github: z.string().optional().nullable(),
    dribble: z.string().optional().nullable(),
    image: z.string().optional().nullable(),
    otherPortfolioLink: z.string().optional().nullable().describe("resume"),
    tagline: z.string().optional().nullable(),
    language: z.nativeEnum(Language).optional().nullable(),
    country: CountrySchema.optional().nullable(),
    nationality: CountrySchema.optional().nullable(),
    bio: z.string().optional().nullable(),
    links: jsonSchema.optional().nullable(),
    optOutNewsletter: z.boolean().optional(),
    reservedFromBeingVouchedUniversal: z.boolean().optional(),
    consentToUniversalVouchSourcing: z.date().optional().nullable(),

    candidateTags: z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
      })
      .strict()
      .array()
      .max(20)
      .optional(),
  })
  .strict();

export const superUserDetailSchema = userDetailSchema.superRefine((data, ctx) => {
  if (!(data.linkedInUrl || data.otherPortfolioLink)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["linkedInUrl"],
      params: { i18n: "invalid_linkedin_or_resume" },
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["otherPortfolioLink"],
      params: { i18n: "invalid_linkedin_or_resume" },
    });
  }
});

export const businessInterestForm = z
  .object({
    name: z.string().min(1).max(50),
    email: z.string().email(),
    phone: z
      .string()
      .refine((el) => isValidPhoneNumber(el), {
        params: { i18n: "invalid_number" },
      })
      .optional(),
  })
  .strict();

const userOrSlug = z.union([
  z.object({
    userId: z.string(),
  }),
  z.object({
    slug: z.string(),
  }),
]);

export const createTrackingInput = z.union([
  z.object({
    type: z.literal("VOUCH_SHARING"),
    vouchId: z.string(),
    listingId: z.string(),
  }),
  z.object({
    type: z.literal("LISTING_SHARING"),
    listingId: z.string(),
    companyId: z.string(),
  }),
  z.object({
    type: z.literal("COMPANY_SHARING"),
    companyId: z.string(),
  }),
  z.object({
    type: z.literal("REQUEST_VOUCH"),
    listingId: z.string(),
  }),
]);

export const trackingInputSchema = createTrackingInput.and(userOrSlug);
